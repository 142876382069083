<div class="container">
    <div class="col title text-center">
        <h1>Technologies we use</h1>
        <h5>Technology is boundless. We are constantly updating our skillset to satisfy our clients needs.</h5>
    </div>

    <div class="techContainer d-flex flex-wrap">
        <div *ngFor="let img of imgSource">
            <img src="{{ img.link }}" alt="{{ img.altName }}" class="slideanim"/>
        </div>
    </div>

</div>
