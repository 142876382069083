import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import("./home/home-routing.module").then(m=>m.HomeRoutingModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import("./about-us/about-us-routing.module").then(m=>m.AboutUsRoutingModule)
  },
  {
    path: 'what-we-do',
    loadChildren: () => import("./what-we-do/what-we-do-routing.module").then(m=>m.WhatWeDoRoutingModule)
  },
  {
    path: 'products',
    loadChildren: () => import("./products/products-routing.module").then(m=>m.ProductsRoutingModule)
  },
  {
    path: 'careers',
    loadChildren: () => import("./careers/careers-routing.module").then(m=>m.CareersRoutingModule)
  },
  {
    path: 'contact',
    loadChildren: () => import("./contact/contact-routing.module").then(m=>m.ContactRoutingModule)
  },
  {
    path: "**",
    redirectTo: '/'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
