<div *ngIf="showLoader">
    <div class="loaderStyle">
      <img src="./.././../assets/Images/loader.gif" alt="loader" height="100%" width="100%">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
</div>

<div *ngIf="!showLoader">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>

<!--Scroll to top-->
<a href="#" id="scroll" style="display: none;"><span></span></a>