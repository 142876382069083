import { Component, HostListener } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showLoader: boolean;
  interval: any;
  timeLeft: number = 3;

  constructor() {
    $(document).ready(function(){ 
      $(window).scroll(function(){ 
          if ($(this).scrollTop() > 100) { 
              $('#scroll').fadeIn(); 
          } else { 
              $('#scroll').fadeOut(); 
          } 
      }); 
      $('#scroll').click(function(){ 
          $("html, body").animate({ scrollTop: 0 }, 600); 
          return false; 
      }); 
  });
  }
  
  title = 'website';

  ngOnInit(): void {

    this.showLoader = true;
    this.startTimer();
  }

  startTimer() {
    setTimeout(() => {
      this.showLoader = false;
    }, 3000);
  }


}
