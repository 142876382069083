<div class="container">
  <div class="col title text-center">
    <h1>Who are we</h1>
    <h5>
      We are set of small and enthusiastic teams with expert skilled people in
      various fields!
    </h5>
  </div>

  <div class="about-card mt-5">
    <div class="card" style="background-color: whitesmoke">
      <div class="card-body">
        <div class="h3 card-title">About the company</div>
        <div class="card-body">
          We started our journey in 2019 with small proficient team as a
          software and product development company helping clients create the
          future with tech expertise and business intelligence. We have
          partnered with several clients and created exponential revenue growth
          for them.
          <br /><br />
          We brainstorm, collaborate and discover ideas to build a better
          future. Our clients satisfaction is our success. Quality product and
          On-time delivery is our highest priority. Moreover, every human being
          working here are equally treated with utmost respect and hospitality.
          <br /><br />
          <div class="row iso-class">
            <div class="col-md-10 col-lg-10 col-12 align-self-center order-0">
              <h5 class="iso-text">
                We are BAS ISO 9001:2015 certified company following all
                International quality standards
              </h5>
            </div>
            <div class="col-md-2 col-lg-2 col-12 order-1">
              <a href="https://www.iso.org/home.html">
                <img
                  src="./../../assets/Images/iso_logo.png"
                  width="50%"
                  alt="BAS ISO logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-card mt-5">
    <div class="card" style="background-color: whitesmoke">
      <div class="card-body">
        <div class="h3 card-title">About the founder</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-8 col-lg-8 col-12 order-0">
              Mr Pradeep K R is the Founder and Managing Director of Sofelit.
              Having a vast knowledge in running a successful business and huge
              interests in the field of computer science, he started Sofelit in
              2019 with a small energetic team.
              <br /><br />
              He sets the strategic direction of the company and focuses on
              ensuring sustainable growth. Under his administration, Sofelit has
              shown enormous positive growth compared to other startup companies
              in short period of time.
              <br /><br />
              <h5 class="font-italic">
                "Leadership is the capacity to translate vision into reality"
              </h5>
              <h6 class="float-right font-italic">~ Warren Bennis</h6>
              <br /><br />
              <h6 class="ceo-mail">
                [[ <a href="mailto:ceo@sofelit.in">ceo@sofelit.in</a> ]]
              </h6>
            </div>
            <div class="col-md-4 col-lg-4 col-12 order-1">
              <div class="ceo-image shadow-lg mx-auto">
                <img
                  src="./../../assets/MD_Photo.jpeg"
                  height="100%"
                  width="100%"
                  alt="Sofelit CEO Pradeep photo"
                />
                <div
                  class="glow-wrap"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Pradeep KR, Founder"
                  data-trigger="hover"
                >
                  <i class="glow"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
