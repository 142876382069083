<div class="cloud-img">
  <img src="./../../assets/Images/cloud.png" height="100%" width="100%" alt="cloud">
</div>


<div class="container container-main">

    <!-- HOMEPAGE SLIDER COMPONENT -->

    <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-2">
          <img src="./../../assets/Images/home-image.webp" alt="home-img" class="home-img">
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-1">
            <div class="container">
                <div class="title">We have helped creating</div> 
                <hr class="line">
                <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel" data-interval="2000">
                    <div class="carousel-inner">
                      <div class="carousel-item active">great products</div>
                      <div class="carousel-item">great revenue</div>
                      <div class="carousel-item">great lives</div>
                      <div class="carousel-item">great ideas</div>
                      <div class="carousel-item">great platform</div>
                    </div>
                  </div>
                  <hr class="line1">
                  <div class="title">for our valuable clients</div>
            </div>
        </div>

    </div>


    
    <!-- QUOTE COMPONENT -->

    <div class="row my-5 text-center quote">
      <h2><blockquote> We create highly polished cross-platform apps and websites for startups and enterprise clients </blockquote></h2>
    </div>

    
    <!-- HOW CAN WE HELP YOU COMPONENT -->

    <h1 class="my-5 cards-title">How can we help you?</h1>
    
    <div class="row pathway">
      <div class="col-lg-4 col-md-4 col-12 mt-2" *ngFor="let cardinfo of cardData">
          <div class="card shadow">
            <img class="card-img-top" src="./../../assets/Images/{{cardinfo.imageName}}" alt="Card image cap">
            <div class="card-body">
              <h4 class="card-title">{{ cardinfo.title }}</h4>
              <p class="card-text"><b>{{ cardinfo.subtitle }}</b><br> {{ cardinfo.detail }}</p>
            </div>
          </div>
      </div>
    </div>
    <br><br>



    <!-- TECHNOLOGIES COMPONENT -->
    
    <h1 class="cards-title mt-5">Technologies we work with</h1>
    

    <div class="row my-3 technologies">

      <div class="col-lg-6 col-md-6 col-sm-12 m-auto">
        <h3>We do not work for all!</h3>
        <h5 class="tech-subtitle">We only work on projects that are really innovative and projects that excites us.</h5>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12">
        <div *ngFor="let skill of skillList">
          <div class="skills" data-percent='{{ skill.datapercent }}'>
            <div class="title-bar">
              <h5>{{ skill.skillset }}</h5>
            </div>
            <span>{{ skill.datapercent }}</span>
            <div class="skillbar" [ngStyle]="{'width': skill.datapercent}"></div>  
          </div>
        </div>
      </div>

    </div>



    <!-- TESTIMONIAL COMPONENT -->

    <h1 class="my-5 cards-title">What our clients say</h1>
    
    <div class="row">

      <div class="col-lg-4 col-md-12 col-sm-12" *ngFor = "let testimonial of testimonialData">
          <div class="testimonial shadow">
              <div class="testimonial-content">
                  <div class="testimonial-icon">
                      <i class="fa fa-quote-left"></i>
                  </div>
                  <p class="description">{{ testimonial.description }}</p>
              </div>
              <h3 class="title">{{ testimonial.title }}</h3>
              <span class="post">{{ testimonial.post }}</span>
          </div>
      </div>

    </div>

</div>

