<section id="footer">
  <div class="body-container">
    <div class="container">
      <hr style="height: 3px" />
      <div class="container">
        <div class="row my-2 align-items-center text-center">
          <div class="col-md-4 copyright-style">Copyright &copy; 2024</div>
          <div class="col-md-4 copyright-style">
            Developed with
            <button class="heart d-inline-block">&#10084;</button> by Sofelit
          </div>
          <div class="col-md-4 copy">
            <a href="#" class="copyright-style mr-3">Terms of use</a> |
            <a href="#" class="copyright-style ml-3">Privacy Policy</a>
          </div>
        </div>
      </div>
      <hr class="mb-0" style="height: 3px" />
    </div>
  </div>
</section>
