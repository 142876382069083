import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss']
})
export class WhatWeDoComponent implements OnInit {

  imgSource = [
    {
      name: 'HTML',
      link: './../../assets/Images/Technologies/HTML5_logo.png',
      altName: 'HTML',
    },
    {
      name: 'CSS',
      link: './../../assets/Images/Technologies/CSS-Logo.png',
      altName: 'CSS',
    },
    {
      name: 'JavaScript',
      link: './../../assets/Images/Technologies/JavaScript-Logo.png',
      altName: 'JavaScript',
    },
    {
      name: 'Angular',
      link: './../../assets/Images/Technologies/angular_logo.png',
      altName: 'Angular',
    },
    {
      name: 'React Native',
      link: './../../assets/Images/Technologies/react-native.png',
      altName: 'React Native',
    },
    {
      name: 'Google Cloud Platform',
      link: './../../assets/Images/Technologies/gcp.png',
      altName: 'Google Cloud Platform',
    },
    {
      name: 'Google Firebase',
      link: './../../assets/Images/Technologies/firebase-logo.png',
      altName: 'Google Firebase',
    },
    {
      name: 'Amazon Web Services',
      link: './../../assets/Images/Technologies/AWS.png',
      altName: 'Amazon Web Services',
    },
    {
      name: 'DigitalOcean',
      link: './../../assets/Images/Technologies/DigitalOcean_logo.png',
      altName: 'DigitalOcean',
    },
    {
      name: 'MongoDB',
      link: './../../assets/Images/Technologies/MongoDB_Logo.png',
      altName: 'MongoDB',
    },
    {
      name: 'MySQL',
      link: './../../assets/Images/Technologies/MySQL-Logo.png',
      altName: 'MySQL',
    },
    {
      name: 'PostgreSQL',
      link: './../../assets/Images/Technologies/postgresql-logo.png',
      altName: 'PostgreSQL',
    },
    {
      name: 'NodeJs',
      link: './../../assets/Images/Technologies/node-js.png',
      altName: 'NodeJs',
    },
    {
      name: 'Python',
      link: './../../assets/Images/Technologies/Python_logo.png',
      altName: 'Python',
    },
    {
      name: 'Adobe Photoshop',
      link: './../../assets/Images/Technologies/photoshop-logo.png',
      altName: 'Adobe Photoshop',
    },
    {
      name: 'Adobe XD',
      link: './../../assets/Images/Technologies/Adobe-XD.png',
      altName: 'Adobe XD',
    },
    {
      name: 'Adobe Illustrator',
      link: './../../assets/Images/Technologies/adobe-illustrator.png',
      altName: 'Adobe Illustrator',
    },
    {
      name: 'Adobe Premiere Pro',
      link: './../../assets/Images/Technologies/adobe-premiere-pro.png',
      altName: 'Adobe Premiere Pro',
    },
    {
      name: 'Adobe Lightroom',
      link: './../../assets/Images/Technologies/adobe-lightroom.png',
      altName: 'Adobe Lightroom',
    },
    {
      name: 'Adobe Audition',
      link: './../../assets/Images/Technologies/adobe-audition.png',
      altName: 'Adobe Audition',
    },
    {
      name: 'Adobe Animate',
      link: './../../assets/Images/Technologies/adobe-animate.png',
      altName: 'Adobe Animate',
    },
    {
      name: 'Adobe Indesign',
      link: './../../assets/Images/Technologies/adobe_Indesign_logo.png',
      altName: 'Adobe Indesign',
    },
    
  
  ];

  constructor() { }

  ngOnInit(): void {
    this.slideInOnScroll();
  }

  /* Scroll function start */
  slideInOnScroll() {
      $(document).ready(function() {

        var animation_elements = $.find('.slideanim');
        var web_window = $(window);
      
        function check_if_in_view() {
          var window_height = web_window.height();
          var window_top_position = web_window.scrollTop();
          var window_bottom_position = (window_top_position + window_height);
      
          $.each(animation_elements, function() {
            var element = $(this);
            var element_height = $(element).outerHeight();
            var element_top_position = $(element).offset().top;
            var element_bottom_position = (element_top_position + element_height);
      
            //check to see if this current container is visible (its viewable if it exists between the viewable space of the viewport)
            if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {            
              $(element).addClass('come-in');
            } else {
              $(element).removeClass('come-in');
            }
          });
      
        }
      
        $(window).on('scroll resize', function() {
            check_if_in_view()
          })
        $(window).trigger('scroll');
      });
  }
  /* Scroll function end */
  
}
