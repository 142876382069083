<nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar menu-bg">

    <div class="container">
      
      <a class="navbar-brand company-name" href="">
        <img src="./assets/Images/{{logoName}}" alt="Sofelit logo" class="company-logo">
      </a>
  
      <button class="navbar-toggler my-auto mr-2" type="button"  aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" id="nav-icon1" (click)="showMobileMenu=!showMobileMenu" [ngClass]="{'open': showMobileMenu }">
          <span></span>
          <span></span>
          <span></span>
      </button>
  
      <div class="collapse navbar-collapse" id="navbarCollapse"  [ngClass]="{ 'show' : showMobileMenu }">
  
        <ul class="navbar-nav my-auto w-100 justify-content-end">
          
          <li class="nav-item">
            <a class="nav-link page-scroll"  (click)="closeNavbar()" [routerLink]="['/home']" [routerLinkActive]="'active'">Home</a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link page-scroll"  (click)="closeNavbar()"  [routerLink]="['/about-us']" [routerLinkActive]="'active'">About Us</a>
          </li> 
  
          <li class="nav-item">
            <a class="nav-link page-scroll"  (click)="closeNavbar()" [routerLink]="['/what-we-do']" [routerLinkActive]="'active'">What we do</a>
          </li>  
          
          <li class="nav-item">
            <a class="nav-link page-scroll"  (click)="closeNavbar()" [routerLink]="['/products']" [routerLinkActive]="'active'">Products</a>
          </li>            
  
          <li class="nav-item">
            <a class="nav-link page-scroll"  (click)="closeNavbar()" [routerLink]="['/careers']" [routerLinkActive]="'active'">Careers</a>
          </li>

          <li class="nav-item">
            <a class="nav-link page-scroll"  (click)="closeNavbar()" [routerLink]="['/contact']" [routerLinkActive]="'active'">Contact</a>
          </li>

        </ul>
  
      </div>
  
    </div>
  
  </nav>