import { Component, OnInit } from "@angular/core";

declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  cardData = [
    {
      imageName: "idea.svg",
      title: "Start",
      subtitle: "Have an innovative idea?",
      detail:
        "We will help you to plan, develop and launch the product easily!",
    },
    {
      imageName: "grow.png",
      title: "Grow",
      subtitle: "Running a business already?",
      detail: "We will help you to grow more, earn more and scale more!",
    },
    {
      imageName: "support.jfif",
      title: "Support",
      subtitle: "Want some additional service?",
      detail: "We help you to streamline the process & lower your trauma!",
    },
  ];

  skillList = [
    {
      datapercent: "74%",
      skillset: "Android app",
    },
    {
      datapercent: "99%",
      skillset: "Web development",
    },
    {
      datapercent: "33%",
      skillset: "IoT",
    },
    {
      datapercent: "91%",
      skillset: "Designing",
    },
    {
      datapercent: "82%",
      skillset: "SEO",
    },
  ];

  testimonialData = [
    {
      description:
        "Rapid development along with quality output make them unique from other startups. All the best Sofelit team.",
      title: "Gokul",
      post: " ",
    },
    {
      description:
        "Passionate team with great communication. The most interesting pros about Sofelit is their quality product delivery.",
      title: "Arun",
      post: " ",
    },
    {
      description:
        "They are small team but highly skilled. Their on-time delivery of product impressed us alot. Thankyou Sofelit.",
      title: "Naveen",
      post: " ",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
