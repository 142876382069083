import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  showMobileMenu : boolean = false;
  logoName: string = "Sofelit logo.png";

  constructor() { }

  ngOnInit(): void {
  }

  closeNavbar() {
    var dropdown_elements = $.find('.navbar-collapse');
    var navbar_toggler = $.find('.navbar-toggler');
    $.each(dropdown_elements, function() {
      let element = $(this);
      element.removeClass('show');
    });
    $.each(navbar_toggler, function() {
      let element = $(this);
      element.removeClass('open');
    });
  }

}
